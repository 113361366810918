import React from 'react';
import ReactDOM from 'react-dom/client';
import { Settings } from 'luxon';

import Layout from './src/components/Layout';
import { SnackBarProvider } from './src/context/SnackBarContext';
import { AuthProvider } from './src/context/AuthContext';
import { routes } from './src/routes';
import config from './src/configs';
import { Helmet } from 'react-helmet';

Settings.defaultLocale = 'de';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <html lang="de" />
        <meta charSet="utf-8" />
        <link rel="icon" href={`${config.frontendBaseUrl}/favicon.ico`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="swiss transcribe ist der einfachste Weg, schnell und sicher Audio-Dateien in Text-Dokumente umzuwandeln – auch in Schweizerdeutsch! 5 Minuten kostenlos."
        />
        <link rel="apple-touch-icon" href={`${config.frontendBaseUrl}/logo192.png`} />

        <link rel="manifest" href={`${config.frontendBaseUrl}/manifest.json`} />

        {/* Basic Open Graph Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="swiss transcribe – Audio in Text umwandeln" />
        <meta
          property="og:description"
          content="swiss transcribe ist der einfachste Weg, schnell und sicher Audio-Dateien in Text-Dokumente umzuwandeln – auch in Schweizerdeutsch! 5 Minuten kostenlos."
        />
        <meta property="og:image" content="https://swisstranscribe.ch/logo512.png" />
        <meta property="og:url" content="https://swisstranscribe.ch" />
        <meta property="og:locale" content="de_CH" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="SwissTranscribe – Audio in Text umwandeln" />
        <meta
          name="twitter:description"
          content="swiss transcribe ist der einfachste Weg, schnell und sicher Audio-Dateien in Text-Dokumente umzuwandeln – auch in Schweizerdeutsch! 5 Minuten kostenlos."
        />
        <meta name="twitter:image" content="https://swisstranscribe.ch/logo512.png" />
        <title>swiss transcribe – Audio in Text umwandeln</title>
        <meta
          name="description"
          content="swiss transcribe ist der einfachste Weg, schnell und sicher Audio-Dateien in Text-Dokumente umzuwandeln – auch in Schweizerdeutsch! 5 Minuten kostenlos."
        ></meta>
        <script type="text/javascript">
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            
            window.hasConsentCookie = document.cookie.match(/^(.*;)?\s*consent\s*=\s*[^;]+(.*)?$/);
            if (!hasConsentCookie) {
              gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
              });
            }`}
        </script>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsId}`}></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.googleAnalyticsId}');`}
        </script>
        <script>
          {`window.consentGranted = () => {
              gtag('consent', 'update', {
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
              });
            }`}
        </script>
      </Helmet>
      <AuthProvider>
        <SnackBarProvider>{element}</SnackBarProvider>
      </AuthProvider>
      ;
    </>
  );
};

export const wrapPageElement = ({ element, props }) => {
  if (props.location.pathname === routes.home.path) {
    return element;
  }
  return <Layout>{element}</Layout>;
};

// needed to prevent console error in build mode
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
