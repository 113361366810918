import { Duration, DateTime } from 'luxon';

const SECONDS_IN_A_DAY = 60 * 60 * 24;

export const getTtl = (days = 30) => {
  const secondsSinceEpoch = Math.round(Date.now() / 1000);

  return secondsSinceEpoch + days * SECONDS_IN_A_DAY;
};

export const getEpochInMs = () => {
  return new Date().getTime();
};

export const minToSec = (min: number) => {
  return min * 60;
};

export const hoursToSec = (min: number) => {
  return min * 60 * 60;
};

export const hoursToMin = (min: number) => {
  return min * 60;
};

export const secondsToMin = (sec: number, roundUp = false) => {
  if (roundUp) {
    return Math.ceil(sec / 60);
  }
  return Math.floor(sec / 60);
};

export const getMidnightTimestamp = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0); // Set the time to midnight (00:00:00)
  return now.getTime();
};

export const getTimestampFromHoursAgo = (hours: number) => {
  const now = new Date();
  return now.setHours(now.getHours() - hours);
};

export const roundSecondsUpToNextMinute = (sec: number) => {
  return Math.ceil(sec / 60) * 60;
};

export const getNewBalanceInMin = (balanceInMin: number, audioDurationInSec: number) => {
  const newBalance = minToSec(balanceInMin) - roundSecondsUpToNextMinute(audioDurationInSec);
  if (newBalance <= 0) {
    return 0;
  }

  return secondsToMin(newBalance);
};

export const secondsToHHMMSS = (second: number) => {
  const duration = Duration.fromObject({ second });
  return duration.toFormat('hh:mm:ss');
};

export const calculateExpirationToISO = (days: number) => {
  const dt = DateTime.now();
  const expiryDate = dt.plus({ days });
  if (!expiryDate.isValid) {
    throw new Error('Invalid date');
  }
  return expiryDate.toISO();
};
