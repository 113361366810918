import { Environment, configProd, configDev, configLocal } from 'common';

const env = (process.env.GATSBY_APP_ENV as Environment) || 'local';

const configOptions = {
  local: configLocal,
  dev: configDev,
  prod: configProd
};

const config = configOptions[env];

export default config;
