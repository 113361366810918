import {
  wrapRootElement as sharedWrapRootElement,
  wrapPageElement as sharedWrapPageElement,
  replaceHydrateFunction as sharedReplaceHydrateFunction
} from './gatsby-shared';

export const wrapRootElement = sharedWrapRootElement;

export const wrapPageElement = sharedWrapPageElement;

// needed to prevent console error in build mode
export const replaceHydrateFunction = sharedReplaceHydrateFunction;
