export const convertBytesToMB = (bytes: number, roundUp = false) => {
  const mb = bytes / (1024 * 1024);
  if (roundUp) {
    return Math.ceil(mb);
  }
  return mb.toFixed(2); // Round to two decimal places
};

export const convertMBToBytes = (mb: number) => {
  return mb * 1024 * 1024;
};

export const convertGBToBytes = (gb: number) => {
  return gb * 1024 * 1024 * 1024;
};

export const convertCHFToCents = (amount: number) => {
  return amount * 100;
};
