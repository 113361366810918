import {
  GetMetadataResponseProps,
  PostSignInRequestProps,
  PostSignInResponseProps,
  PostVerifyRequestProps,
  PostVerifyResponseProps
} from 'common';
import { axiosInstance } from './common';

export const getMetadata = async () => {
  try {
    const result = await axiosInstance.get<GetMetadataResponseProps>('/api/auth/metadata');

    return result.data;
  } catch {
    return null;
  }
};

export const postVerify = async ({ email, code, id }: PostVerifyRequestProps) => {
  try {
    const result = await axiosInstance.post<PostVerifyResponseProps>('api/auth/verify', {
      email,
      code,
      id
    });

    return result.data;
  } catch {
    return null;
  }
};

export const postSignIn = async (data: PostSignInRequestProps) => {
  try {
    const result = await axiosInstance.post<PostSignInResponseProps>('api/auth/sign-in', {
      email: data.email
    });

    return result.data;
  } catch {
    return null;
  }
};

export const getSignOut = async () => {
  try {
    const result = await axiosInstance.get<void>('api/auth/sign-out');

    return result.data;
  } catch {
    return null;
  }
};
