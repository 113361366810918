import { Environment } from '../types';
import { Config } from './types';

// ----------- THIS DATA IS HYDRATED ON THE FRONTEND!!!! ------------
export const configProd: Config = {
  environment: Environment.PROD,
  frontendBaseUrl: 'https://swisstranscribe.ch',
  frontendCockpitBaseUrl: 'https://swisstranscribe.ch',
  apiBaseUrl: 'https://services.swisstranscribe.ch',
  googleAnalyticsId: 'G-QZ4PMSQ5RB',
  dropZone: {
    accept: {
      'audio/*': ['.mp3', '.mp4', '.mpeg', '.mpga', '.m4a', '.wav', '.webm', '.ogg']
    }
  }
};
