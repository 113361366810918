import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Link, Typography } from '@mui/material';

type Props = {
  close?: () => void;
};

const Component = ({ close }: Props) => {
  return (
    <Dialog open onClose={close} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">Datenschutz</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h5" sx={{ mb: 1 }}>
          1. Worum geht es in diesen Datenschutzhinweisen?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Swiss Transcribe (nachstehend auch «wir», «uns») beschafft und bearbeitet Personendaten, die Sie oder auch andere Personen (sog.
          «Dritte») betreffen. Wir verwenden den Begriff «Daten» hier gleichbedeutend mit «Personendaten» oder «personenbezogene Daten».In
          diesen Datenschutzhinweisen beschreiben wir, was mit Ihren Daten geschieht, wenn Sie unsere Website verwenden, unsere
          Dienstleistungen oder Produkte beziehen, anderweitig mit uns im Rahmen eines Vertrags in Verbindung stehen, mit uns kommunizieren
          oder sonst mit uns Kontakt haben. Gegebenenfalls werden wir Sie durch eine rechtzeitige schriftliche Mitteilung über zusätzliche,
          nicht in dieser Datenschutzerklärung erwähnte Bearbeitungsaktivitäten informieren. Zusätzlich können wir Sie über die Bearbeitung
          Ihrer Daten separat informieren, z.B. in Einwilligungserklärungen, Vertragsbedingungen, zusätzlichen Datenschutzerklärungen,
          Formularen und Hinweisen.Wenn Sie uns Daten über andere Personen wie z.B. Familienmitglieder, Arbeitskollegen etc. übermitteln
          bzw. bekanntgeben, gehen wir davon aus, dass Sie dazu befugt sind und dass diese Daten richtig sind. Mit der Übermittlung von
          Daten über Dritte bestätigen Sie dies. Bitte stellen Sie auch sicher, dass diese Dritten über diese Datenschutzerklärung
          informiert wurden.Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung («DSGVO»), das Schweizer
          Datenschutzgesetz («DSG») und das revidierte Schweizer Datenschutzgesetz («revDSG») ausgelegt. Ob und inwieweit diese Gesetze
          anwendbar sind, hängt jedoch vom Einzelfall ab.
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          2. Wer ist für die Bearbeitung Ihrer Daten verantwortlich?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Für die in diesen Datenschutzhinweisen beschriebenen Datenbearbeitungen von Swiss Transcribe ist datenschutzrechtlich die Candoo
          GmbH, Via Surpunt 44, 7500 St. Moritz, verantwortlich, soweit im Einzelfall nichts Anderes kommuniziert wird z.B. in weiteren
          Datenschutzerklärungen, auf Formularen oder in Verträgen. Diese Datenschutzerklärung gilt, sofern nichts anders kommuniziert
          wird.Sie können uns für Ihre Datenschutzanliegen und die Ausübung Ihrer Rechte gemäss Ziff. 11 wie folgt erreichen: <br />
          Candoo GmbH, Via Surpunt 44, 7500 St. Moritz, info@swisstranscribe.dev
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          3. Welche Daten bearbeiten wir?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Wir bearbeiten verschiedene Kategorien von Daten über Sie. Die wichtigsten Kategorien sind folgende:
          <br />
          <b>Technische Daten:</b> Wenn Sie unsere Website oder andere elektronische Angebote (z.B. kostenloses WLAN) verwenden, erheben wir
          die IP-Adresse Ihres Endgeräts und weitere technische Daten, um die Funktionalität und Sicherheit dieser Angebote sicherzustellen.
          Zu diesen Daten gehören auch Protokolle, in denen die Verwendung unserer Systeme aufgezeichnet wird. Wir bewahren technische Daten
          in der Regel für 6 Monate auf. Um die Funktionalität dieser Angebote sicherzustellen, können wir Ihnen bzw. Ihrem Endgerät auch
          einen individuellen Code zuweisen (z.B. in Form eines Cookies, vgl. dazu Ziff. 12). Die technischen Daten für sich lassen
          grundsätzlich keine Rückschlüsse auf Ihre Identität zu. Im Rahmen von Benutzerkonten, Registrierungen, Zugangskontrollen oder der
          Abwicklung von Verträgen können sie jedoch mit anderen Datenkategorien (und so ggf. mit Ihrer Person) verknüpft werden.
          <br />
          <b>Registrierungsdaten: </b>Bestimmte Angebote z.B. von Wettbewerben und Dienstleistungen (z.B. Login-Bereiche unserer Website,
          Newsletter-Versand, kostenloser WLAN-Zugang etc.) können nur mit einem Benutzerkonto oder einer Registrierung genutzt werden, die
          direkt bei uns oder über unsere externen Login-Dienstleister erfolgen kann. Dabei müssen Sie uns bestimmte Daten angeben, und wir
          erheben Daten über die Nutzung des Angebots oder der Dienstleistung. Wenn wir Ihnen einen Gutschein für einen unserer
          Vertragspartner ausstellen, übermitteln wir dem jeweiligen Vertragspartner unter Umständen gewisse Ihrer Registrierungsdaten oder
          erhalten solche (vgl. Ziff. 7). Bei Zugangskontrollen zu bestimmten Anlagen können Registrierungsdaten anfallen; je nach
          Kontrollsystem auch biometrische Daten. Wir bewahren Registrierungsdaten in der Regel während 2 Monaten nach dem Ende der Nutzung
          der Dienstleistung oder der Auflösung des Nutzerkontos auf.
          <br />
          <b>Kommunikationsdaten:</b> Wenn Sie mit uns über das Kontaktformular, per E-Mail, Telefon oder Chat, brieflich oder über sonstige
          Kommunikationsmittel in Kontakt stehen, erfassen wir die zwischen Ihnen und uns ausgetauschten Daten, einschliesslich Ihrer
          Kontaktdaten und der Randdaten der Kommunikation. Wenn wir Ihre Identität feststellen wollen oder müssen, z.B. bei einem von Ihnen
          gestellten Auskunftsbegehren, einem Antrag für Medienzugang etc., erheben wir Daten, um Sie zu identifizieren (z.B. eine Kopie
          eines Ausweises). Wir bewahren diese Daten in der Regel während 12 Monaten ab dem letzten Austausch mit Ihnen auf. Diese Frist
          kann länger sein, soweit dies aus Beweisgründen oder zur Einhaltung gesetzlicher oder vertraglicher Vorgaben erforderlich oder
          technisch bedingt ist. E-Mails in persönlichen Postfächern und schriftliche Korrespondenzen werden in der Regel mindestens 10
          Jahre aufbewahrt. Chats werden in der Regel während 2.5 Jahren aufbewahrt.
          <br />
          <b>Stammdaten:</b> Als Stammdaten bezeichnen wir die Grunddaten, die wir nebst den Vertragsdaten (siehe unten) für die Abwicklung
          unserer vertraglichen und sonstigen geschäftlichen Beziehungen oder für Marketing- und Werbezwecke benötigen, wie Name,
          Kontaktdaten und Informationen z.B. über Ihre Rolle und Funktion, Ihre Bankverbindung(en), Ihr Geburtsdatum, die Kundenhistorie,
          Vollmachten, Unterschriftsberechtigungen und Einwilligungserklärungen. Ihre Stammdaten bearbeiten wir, wenn Sie ein Kunde oder
          sonstiger geschäftlicher Kontakt sind oder für einen solchen tätig sind (z.B. als Kontaktperson des Geschäftspartners), oder weil
          wir Sie für unsere eigenen Zwecke oder die Zwecke eines Vertragspartners ansprechen wollen (z.B. im Rahmen von Marketing und
          Werbung, mit Einladungen an Anlässe, mit Gutscheinen, mit Newslettern etc.). Wir erhalten Stammdaten von Ihnen selbst (z.B. bei
          einem Kauf oder im Rahmen einer Registrierung), von Stellen, für die Sie tätig sind, oder von Dritten wie z.B. unseren
          Vertragspartnern, Verbänden und Adresshändlern und aus öffentlich zugänglichen Quellen wie z.B. öffentlichen Registern oder dem
          Internet (Websites, Social Media etc.). Wir können im Rahmen von Stammdaten auch Gesundheitsdaten und Angaben über Dritte
          bearbeiten. Stammdaten können wir auch von unseren Aktionären und Anlegern erheben. Wir bewahren diese Daten in der Regel während
          10 Jahren ab dem letzten Austausch mit Ihnen auf, mindestens aber ab Vertragsende. Diese Frist kann länger sein, soweit dies aus
          Beweisgründen oder zur Einhaltung gesetzlicher oder vertraglichen Vorgaben erforderlich oder technisch bedingt ist. Bei reinen
          Marketing- und Werbekontakten ist die Frist normalerweise wesentlich kürzer, meist nicht mehr als 2 Jahre seit dem letzten
          Kontakt.
          <br />
          <b>Vertragsdaten:</b> Das sind Daten, die im Zusammenhang mit einem Vertragsschluss bzw. der Vertragsabwicklung anfallen, z.B.
          Angaben über Verträge und den zu erbringenden oder erbrachten Leistungen, sowie Daten aus dem Vorfeld eines Vertragsabschlusses,
          die zur Abwicklung erforderlichen oder verwendeten Angaben und Angaben über Reaktionen (z.B. Beschwerden oder Angaben zur
          Zufriedenheit etc.). Auch Angaben über Dritte gehören dazu. Diese Daten erheben wir i.d.R. von Ihnen, von Vertragspartnern und von
          in die Abwicklung des Vertrages involvierten Dritten, aber auch von Drittquellen (z.B. Anbietern von Bonitätsdaten) und aus
          öffentlich zugänglichen Quellen. Wir bewahren diese Daten in der Regel während 10 Jahren ab der letzten Vertragsaktivität auf,
          mindestens aber ab Vertragsende. Diese Frist kann länger sein, soweit dies aus Beweisgründen oder zur Einhaltung gesetzlicher oder
          vertraglichen Vorgaben erforderlich oder technisch bedingt ist.
          <br />
          <b>Verhaltens- und Präferenzdaten: </b>Je nachdem, in welcher Beziehung wir zu Ihnen stehen, versuchen wir Sie kennenzulernen und
          unsere Produkte, Dienstleistungen und Angebote besser auf Sie auszurichten. Dazu erheben und nutzen wir Daten über Ihr Verhalten
          und Ihre Präferenzen. Wir tun dies, indem wir Angaben über Ihr Verhalten in unserem Bereich auswerten, und wir können diese
          Angaben auch mit Angaben von Dritten – auch aus öffentlich zugänglichen Quellen – ergänzen. Gestützt darauf können wir etwa die
          Wahrscheinlichkeit berechnen, dass Sie bestimmte Leistungen in Anspruch nehmen oder sich auf eine bestimmte Weise verhalten. Die
          dazu bearbeiteten Daten sind uns teilweise bereits bekannt (z.B. wenn Sie unsere Leistungen in Anspruch nehmen), oder wir gewinnen
          diese Daten, indem wir Ihr Verhalten aufzeichnen (z.B. wie Sie auf unserer Website navigieren). Wir anonymisieren oder löschen
          diese Daten, wenn sie für die verfolgten Zwecke nicht mehr aussagekräftig sind, was je nach der Art der Daten zwischen 2-3 Wochen
          und 24 Monaten (bei Produkte- und Dienstleistungspräferenzen) der Fall sein kann. Diese Frist kann länger sein, soweit dies aus
          Beweisgründen oder zur Einhaltung gesetzlicher oder vertraglichen Vorgaben erforderlich oder technisch bedingt ist. Wie das
          Tracking auf unserer Website funktioniert, beschreiben wir in Ziff. 12.
          <br />
          <b>Sonstige Daten:</b> Daten von Ihnen erheben wir auch in anderen Situationen. Im Zusammenhang mit behördlichen oder
          gerichtlichen Verfahren etwa fallen Daten an (wie Akten, Beweismittel etc.), die sich auch auf Sie beziehen können. Aus Gründen
          des Gesundheitsschutzes können wir ebenfalls Daten erheben (z.B. im Rahmen von Schutzkonzepten). Wir können Fotos, Videos und
          Tonaufnahmen erhalten oder herstellen, in denen Sie erkennbar sein können (z.B. an Anlässen, durch Sicherheitskameras etc.). Wir
          können auch Daten darüber erheben, wer wann bestimmte Gebäude betritt oder entsprechende Zugangsrechte hat (inkl. bei
          Zugangskontrollen, gestützt auf Registrierungsdaten oder Besucherlisten etc.), wer wann an Anlässen oder Aktionen (z.B.
          Wettbewerben) teilnimmt oder wer wann unsere Infrastruktur und Systeme verwendet. Schliesslich erheben und bearbeiten wir Daten
          über unsere Aktionäre und anderen Anleger; neben Stammdaten sind dies u.a. Angaben für die entsprechenden Register, bezüglich der
          Ausübung ihrer Rechte und der Durchführung von Anlässen (z.B. Generalversammlungen). Die Aufbewahrungsfrist dieser Daten richtet
          sich nach dem Zweck und wird auf das Erforderliche beschränkt. Das reicht von einigen wenigen Tagen bei vielen der
          Sicherheitskameras und in der Regel einigen Wochen bei Daten für ein Contact Tracing über Besucherdaten, die in der Regel während
          3 Monaten aufbewahrt werden bis hin zu Berichten über Anlässe mit Bildern, die einige Jahre oder länger aufbewahrt werden können.
          Daten über Sie als Aktionär oder sonstiger Anleger werden entsprechend den gesellschaftsrechtlichen Vorgaben aufbewahrt, in jedem
          Fall aber solange Sie investiert sind. Vieler der in dieser Ziff. 3 genannten Daten geben Sie uns selbst bekannt (z.B. über
          Formulare, im Rahmen der Kommunikation mit uns, im Zusammenhang mit Verträgen, bei der Verwendung der Website etc.). Sie sind dazu
          nicht verpflichtet, unter Vorbehalt von Einzelfällen, z.B. im Rahmen von verbindlichen Schutzkonzepten (gesetzliche
          Verpflichtungen). Wenn Sie mit uns Verträge schliessen oder Leistungen beanspruchen wollen, müssen Sie uns zudem im Rahmen Ihrer
          vertraglichen Verpflichtung gemäss dem einschlägigen Vertrag Daten bereitstellen, insbesondere Stamm-, Vertrags- und
          Registrierungsdaten. Bei der Nutzung unserer Website ist die Bearbeitung technischer Daten unvermeidlich. Wenn Sie Zugang zu
          bestimmten Systemen oder Gebäuden erhalten möchten, müssen Sie uns Registrierungsdaten angeben. Bei Verhaltens- und Präferenzdaten
          haben Sie jedoch grundsätzlich die Möglichkeit, zu widersprechen oder keine Einwilligung zu geben.Soweit dies nicht unzulässig
          ist, entnehmen wir Daten auch öffentlich zugänglichen Quellen (z.B. Betreibungsregister, Grundbücher, Handelsregister, Medien oder
          dem Internet inkl. Social Media) oder erhalten Daten von anderen Unternehmen welche Dienstleistungen für Sie erbringen, von
          Behörden und von sonstigen Dritten (wie z.B. Kreditauskunfteien, Adresshändler, Verbände, Vertragspartner, Internet-Analysedienste
          etc.).
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          4. Zu welchen Zwecken bearbeiten wir Ihre Daten?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Wir bearbeiten Ihre Daten zu den Zwecken, die wir im Folgenden erläutern. Weitere Hinweise für den Online-Bereich finden Sie in
          Ziff. 12 und 13. Diese Zwecke bzw. die ihnen zugrundeliegenden Ziele stellen berechtigte Interessen von uns und ggf. von Dritten
          dar. Sie finden weitere Angaben zu den Rechtsgrundlagen unserer Bearbeitung in Ziff. 5. Wir bearbeiten Ihre Daten zu Zwecken im
          Zusammenhang mit der Kommunikation mit Ihnen, insbesondere zur Beantwortung von Anfragen und der Geltendmachung Ihrer Rechte
          (Ziff. 11) und um Sie bei Rückfragen zu kontaktieren. Hierzu verwenden wir insbesondere Kommunikationsdaten und Stammdaten und im
          Zusammenhang mit von Ihnen genutzten Angeboten und Dienstleistungen auch Registrierungsdaten. Wir bewahren diese Daten auf, um
          unsere Kommunikation mit Ihnen zu dokumentieren, für Schulungszwecke, zur Qualitätssicherung und für Nachfragen.Daten bearbeiten
          wir für die Aufnahme, Verwaltung und Abwicklung von Vertragsbeziehungen. Wir bearbeiten Daten für Marketingzwecke und
          zur Beziehungspflege, z.B. um unseren Kunden und anderen Vertragspartnern personalisierte Werbung zu Produkten und
          Dienstleistungen von uns und von Dritten (z.B. von Werbe-Vertragspartnern) zu senden. Das kann z.B. in Form von Newslettern und
          anderen regelmässigen Kontakten (elektronisch, per Post, telefonisch), über andere Kanäle, für welche wir von Ihnen
          Kontaktinformationen haben, aber auch im Rahmen von einzelnen Marketingaktionen (z.B. Anlässe, Wettbewerbe etc.) erfolgen und auch
          Gratisleistungen enthalten (z.B. Einladungen, Gutscheine etc.). Sie können solche Kontakte jederzeit ablehnen (siehe am Ende
          dieser Ziff. 4) bzw. eine Einwilligung in die Kontaktaufnahme für Werbezwecke verweigern oder widerrufen. Mit Ihrer Einwilligung
          können wir unsere Online-Werbung im Internet zielgerichteter auf Sie ausrichten (dazu Ziff. 12). Schliesslich wollen wir auch
          Vertragspartnern von uns ermöglichen, unsere Kunden und andere Vertragspartner zu Werbezwecken anzusprechen (dazu Ziff. 7).Ihre
          Daten bearbeiten wir weiter zur Marktforschung, zur Verbesserung unserer Dienstleistungen und unseres Betriebs und
          zur Produktentwicklung. Wir können Ihre Daten auch zu Sicherheitszwecken und für die Zugangskontrolle bearbeiten.Wir bearbeiten
          Personendaten zur Einhaltung von Gesetzen, Weisungen und Empfehlungen von Behörden und interner Regularien («Compliance»). Wir
          bearbeiten Daten auch für Zwecke unseres Risikomanagements und im Rahmen einer umsichtigen Unternehmensführung, einschliesslich
          Betriebsorganisation und Unternehmensentwicklung. Wir können Ihre Daten zu weiteren Zwecken bearbeiten, z.B. im Rahmen unserer
          internen Abläufe und Administration oder zu Schulungs- und Qualitätssicherungszwecken.
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          5. Auf welcher Grundlage bearbeiten wir Ihre Daten?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Soweit wir Sie für bestimmte Bearbeitungen um Ihre Einwilligung bitten (z.B. für die Bearbeitung von besonders schützenswerten
          Personendaten, für Marketingmailings, und für die Werbesteuerungs- und Verhaltensanalyse auf der Website), informieren wir Sie
          gesondert über die entsprechenden Zwecke der Bearbeitung. Einwilligungen können Sie jederzeit durch schriftliche Mitteilung
          (postalisch) oder, wo nicht anders angegeben oder vereinbart, per E-Mail an uns jederzeit mit Wirkung für die Zukunft widerrufen;
          unsere Kontaktangaben finden Sie in Ziff. 2. Für den Widerruf Ihrer Einwilligung beim Online-Tracking vgl. Ziff. 12. Wo Sie über
          ein Benutzerkonto verfügen, kann ein Widerruf oder eine Kontaktnahme mit uns ggf. auch über die betreffende Website oder sonstige
          Dienstleistung durchgeführt werden. Sobald wir die Mitteilung über den Widerruf Ihrer Einwilligung erhalten haben, werden wir Ihre
          Daten nicht mehr für die Zwecke bearbeiten, denen Sie ursprünglich zugestimmt haben, es sei denn, wir haben eine andere
          Rechtsgrundlage dafür. Durch den Widerruf Ihrer Einwilligung wird die Rechtmässigkeit der aufgrund der Einwilligung bis zum
          Widerruf erfolgten Bearbeitung nicht berührt.Wo wir Sie nicht um Ihre Einwilligung für eine Bearbeitung bitten, stützen wir die
          Bearbeitung Ihrer Personendaten darauf, dass die Bearbeitung für die Anbahnung oder Abwicklung eines Vertrags mit Ihnen (oder der
          von Ihnen vertretenen Stelle) erforderlich ist oder dass wir oder Dritte ein berechtigtes Interesse daran haben, so insbesondere
          um die vorstehend unter Ziff. 4 beschriebenen Zwecke und damit verbundenen Ziele zu verfolgen und entsprechende Massnahmen
          durchführen zu können. Zu unseren berechtigten Interessen gehört auch die Einhaltung von gesetzlichen Vorschriften, soweit diese
          nicht ohnehin bereits vom jeweils anwendbaren Datenschutzrecht als Rechtsgrundlage anerkannt ist (z.B. bei der DSGVO das Recht im
          EWR und in der Schweiz). Dazu gehört aber auch die Vermarktung unserer Produkte und Dienstleistungen, das Interesse, unsere Märkte
          besser zu verstehen und unser Unternehmen, einschliesslich des operativen Betriebs, sicher und effizient zu führen und
          weiterzuentwickeln. Wenn wir sensible Daten erhalten (z.B. Gesundheitsdaten, Angaben zu politischen, religiösen oder
          weltanschaulichen Ansichten oder biometrische Daten zur Identifikation), können wir Ihre Daten auch gestützt auf andere
          Rechtsgrundlagen bearbeiten, z.B. im Falle von Auseinandersetzungen aufgrund der Notwendigkeit der Bearbeitung für einen etwaigen
          Prozess oder die Durchsetzung oder Abwehr von Rechtsansprüchen. In Einzelfällen können andere Rechtsgründe zum Tragen kommen, was
          wir Ihnen soweit erforderlich separat kommunizieren.
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          6. Was gilt bei Profiling und automatisierten Einzelentscheiden?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Wir können bestimmte Ihrer persönlichen Eigenschaften zu den in Ziff. 4 genannten Zwecken anhand Ihrer Daten (Ziff. 3)
          automatisiert bewerten («Profiling»), wenn wir Präferenzdaten ermitteln wollen, aber auch um Missbrauchs- und Sicherheitsrisiken
          festzustellen, statistische Auswertungen vorzunehmen oder für betriebliche Planungszwecke. Zu denselben Zwecken können wir auch
          Profile erstellen, d.h. wir können Verhaltens- und Präferenzdaten, aber auch Stamm- und Vertragsdaten und Ihnen zugeordnete
          technische Daten kombinieren, um Sie als Person mit ihren unterschiedlichen Interessen und sonstigen Eigenschaften besser zu
          verstehen.In beiden Fällen achten wir auf die Verhältnismässigkeit und Zuverlässigkeit der Ergebnisse und treffen Massnahmen gegen
          eine missbräuchliche Verwendung dieser Profile oder eines Profiling. Können diese Rechtswirkungen oder erhebliche Nachteile für
          Sie mit sich bringen, sehen wir grundsätzlich eine manuelle Überprüfung vor.
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          7. Wem geben wir Ihre Daten bekannt?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Im Zusammenhang mit unseren Verträgen, der Website, unseren Dienstleistungen und Produkten, unseren rechtlichen Pflichten oder
          sonst zur Wahrung unserer berechtigten Interessen und den weiteren in Ziff. 4 aufgeführten Zwecken übermitteln wir Ihre
          Personendaten auch an Dritte, insbesondere an die folgenden Kategorien von Empfängern:
          <br />
          <b>Dienstleister:</b> Wir arbeiten mit Dienstleistern im Inland zusammen, die in unserem Auftrag oder in gemeinsamer
          Verantwortlichkeit mit uns Daten über Sie bearbeiten oder in eigener Verantwortlichkeit Daten über Sie von uns erhalten (z.B.
          IT-Provider, Versandunternehmen, Werbedienstleister, Login-Dienstleister, Reinigungsunternehmen, Bewachungsunternehmen, Banken,
          Versicherungen, Inkassofirmen, Wirtschaftsauskunfteien, oder Adressprüfer). Parter als eigenständige Verantwortliche: Im Rahmen
          unseres Online-Angebotes haben Sie die Möglichkeit von unseren Kooperationspartnern Waren und Dienstleistungen direkt zu beziehen.
          In diesem Zusammenhang werden Ihre Daten dem Partner (Leistungserbringer) zur Vertragserfüllung übertragen. Die Informationen des
          Leistungserbringers mit dem Sie ein Vertragsverhältnis eingehen sind jeweils im Bestellvorgang ersichtlich. Die Leistungserbringer
          bearbeiten in eigener Verantwortlichkeit die von uns weitergeleiteten personenbezogene Daten und informieren Sie hierüber mit
          eigenen Datenschutzhinweisen.
          <br />
          <b>Vertragspartner inklusive Kunden:</b> Gemeint sind zunächst die Kunden (z.B. Leistungsbezüger) und anderen Vertragspartner von
          uns, weil sich diese Datenübermittlung aus diesen Verträgen ergibt. Sie erhalten z.B. Registrierungsdaten zu ausgegebenen und
          eingelösten Gutscheinen, Einladungen etc. Wenn Sie für einen solchen Vertragspartner selbst tätig sind, können wir diesem auch in
          diesem Zusammenhang Daten über Sie übermitteln. Dazu können auch Gesundheitsdaten gehören. Zu den Empfängern gehören weiter
          Vertragspartner, mit denen wir kooperieren oder die für uns Werbung treiben und denen wir deshalb Daten über Sie für Analyse- und
          Marketingzwecke übermitteln (das können wiederum Leistungsbezüger sein, aber z.B. auch Sponsoren und Anbieter von Online-Werbung).
          Wir verlangen von diesen Partnern, dass Sie Ihnen nur dann Werbung zusenden oder basierend auf Ihren Daten ausspielen, wenn Sie
          dem zugestimmt haben (für den Online-Bereich vgl. Ziff. 12). <br />
          <b>Behörden:</b> Wir können Personendaten an Ämter, Gerichte und andere Behörden im In- und Ausland weitergeben, wenn wir dazu
          rechtlich verpflichtet oder berechtigt sind oder dies zur Wahrung unserer Interessen als erforderlich erscheint. Dazu können auch
          Gesundheitsdaten gehören. Die Behörden bearbeiten in eigener Verantwortlichkeit Daten über Sie, die sie von uns erhalten.
          <br />
          <b>Weitere Personen:</b> Gemeint sind andere Fällen, wo sich der Einbezug von Dritten aus den Zwecken gemäss Ziff. 4 ergibt, z.B.
          Leistungsempfänger, Medien und Vereine, an denen wir mitwirken oder wenn Sie Teil einer unserer Publikationen sind.Alle diese
          Kategorien von Empfängern können ihrerseits Dritte beiziehen, so dass Ihre Daten auch diesen zugänglich werden können. Die
          Bearbeitung durch bestimmte Dritte können wir beschränken (z.B. IT-Provider), jene anderer Dritter aber nicht (z.B. Behörden,
          Banken etc.). Wir behalten uns diese Datenbekanntgaben auch dann vor, wenn sie geheime Daten betreffen (es sei denn, wir haben mit
          Ihnen ausdrücklich vereinbart, dass wir diese Daten nicht an bestimmte Dritte weitergeben, es sei denn wir wären dazu gesetzlich
          verpflichtet). Ungeachtet dessen unterstehen Ihre Daten auch nach einer Bekanntgabe in der Schweiz und im restlichen Europa
          weiterhin einem angemessenen Datenschutz. Für die Bekanntgabe in andere Länder gelten die Bestimmungen von Ziff. 8. Möchten Sie
          nicht, dass bestimmte Daten weitergegeben werden, teilen Sie uns dies bitte mit, damit wir prüfen können, ob und inwieweit wir
          Ihnen entgegenkommen können (Ziff. 2).Wir ermöglichen auch bestimmten Dritten, auf unserer Website und bei Anlässen von uns
          ihrerseits Personendaten von Ihnen zu erheben (z.B. Medienfotografen, Anbieter von Tools, die wir auf unserer Website eingebunden
          haben etc.). Soweit wir nicht in entscheidender Weise an diesen Datenerhebungen beteiligt sind, sind diese Dritten alleine dafür
          verantwortlich. Bei Anliegen und zur Geltendmachung Ihrer Datenschutzrechte wenden Sie sich bitte direkt an diese Dritten. Vgl.
          Ziff. 12 für die Website.
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          8. Gelangen Ihre Personendaten auch ins Ausland?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Wie in Ziff. 7 erläutert, geben wir Daten auch anderen Stellen bekannt. Diese befinden sich nicht nur in der Schweiz. Ihre Daten
          können daher sowohl in Europa als auch in der USA (Vereinigte Statten) bearbeitet werden; in Ausnahmefällen aber in jedem Land der
          Welt.Befindet sich ein Empfänger in einem Land ohne angemessenen gesetzlichen Datenschutz, verpflichten wir den Empfänger
          vertraglich zur Einhaltung des anwendbaren Datenschutzes (dazu verwenden wir die revidierten Standardvertragsklauseln der
          Europäischen Kommission, die hier: 
          <Link href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?" target="_blank">
            https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?
          </Link>
           abrufbar sind), soweit er nicht bereits einem gesetzlich anerkannten Regelwerk zur Sicherstellung des Datenschutzes unterliegt
          und wir uns nicht auf eine Ausnahmebestimmung stützen können. Eine Ausnahme kann namentlich bei Rechtsverfahren im Ausland gelten,
          aber auch in Fällen überwiegender öffentlicher Interessen oder wenn eine Vertragsabwicklung eine solche Bekanntgabe erfordert,
          wenn Sie eingewilligt haben oder wenn es sich um von Ihnen allgemein zugänglich gemachte Daten handelt, deren Bearbeitung Sie
          nicht widersprochen haben. Beachten Sie bitte auch, dass Daten, die über das Internet ausgetauscht werden, häufig über
          Drittstaaten geleitet werden. Ihre Daten können daher auch dann ins Ausland gelangen, wenn sich Absender und Empfänger im gleichen
          Land befinden.
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          9. Wie lange bearbeiten wir Ihre Daten?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Wir bearbeiten Ihre Daten so lange, wie es unsere Bearbeitungszwecke, die gesetzlichen Aufbewahrungsfristen und unsere
          berechtigten Interessen der Bearbeitung zu Dokumentations- und Beweiszwecken es verlangen oder eine Speicherung technisch bedingt
          ist. Weitere Angaben zur jeweiligen Speicher- und Bearbeitungsdauer finden Sie jeweils bei den einzelnen Datenkategorien in Ziff.
          3 bzw. bei den Cookie-Kategorien in Ziff. 12. Stehen keine rechtlichen oder vertraglichen Pflichten entgegen, löschen oder
          anonymisieren wir Ihre Daten nach Ablauf der Speicher- oder Bearbeitungsdauer im Rahmen unserer üblichen Abläufe.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          10. Wie schützen wir Ihre Daten?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Wir treffen angemessene Sicherheitsmassnahmen, um die Vertraulichkeit, Integrität und Verfügbarkeit Ihrer Personendaten zu wahren,
          um sie gegen unberechtigte oder unrechtmässige Bearbeitungen zu schützen und den Gefahren des Verlusts, einer unbeabsichtigten
          Veränderung, einer ungewollten Offenlegung oder eines unberechtigten Zugriffs entgegenzuwirken.
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          11. Welche Rechte haben Sie?
        </Typography>

        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Das anwendbare Datenschutzrecht gewährt Ihnen unter bestimmten Umständen das Recht, der Bearbeitung Ihrer Daten zu widersprechen,
          insbesondere jener für Zwecke des Direktmarketings, des für Direktwerbung betriebenen Profilings und weiterer berechtigter
          Interessen an der Bearbeitung.Um Ihnen die Kontrolle über die Bearbeitung Ihrer Personendaten zu erleichtern, haben Sie im
          Zusammenhang mit unserer Datenbearbeitung je nach anwendbarem Datenschutzrecht auch folgende Rechte: Das Recht, von uns Auskunft
          zu verlangen, ob und welche Daten wir von Ihnen bearbeiten; das Recht, dass wir Daten korrigieren, wenn sie unrichtig sind; das
          Recht, die Löschung von Daten zu verlangen; das Recht, von uns die Herausgabe bestimmter Personendaten in einem gängigen
          elektronischen Format oder ihre Übertragung an einen anderen Verantwortlichen zu verlangen; das Recht, eine Einwilligung zu
          widerrufen, soweit unsere Bearbeitung auf Ihrer Einwilligung beruht; das Recht auf Nachfrage weitere Informationen zu erhalten,
          die für die Ausübung dieser Rechte erforderlich sind; das Recht, bei automatisierten Einzelentscheidungen (Ziff. 6) Ihren
          Standpunkt darzulegen und zu verlangen, dass die Entscheidung von einer natürlichen Person überprüft wird. Wenn Sie uns gegenüber
          die oben genannten Rechte ausüben wollen, wenden Sie sich bitte schriftlich, bei uns vor Ort oder, wo nicht anders angegeben oder
          vereinbart, per E-Mail an uns; unsere Kontaktangaben finden Sie in Ziff. 2. Damit wir einen Missbrauch ausschliessen können,
          müssen wir Sie identifizieren (z.B. mit einer Ausweiskopie, soweit dies nicht anders möglich ist).Sie haben diese Rechte auch
          gegenüber anderen Stellen, die mit uns eigenverantwortlich zusammenarbeiten – wenden Sie sich bitte direkt an diese, wenn Sie
          Rechte im Zusammenhang mit deren Bearbeitung wahrnehmen wollen. Angaben zu unseren wichtigen Kooperationspartnern und
          Dienstleistern finden Sie in Ziff. 7, weitere Angaben in Ziff. 12.Bitte beachten Sie, dass für diese Rechte nach dem anwendbaren
          Datenschutzrecht Voraussetzungen, Ausnahmen oder Einschränkungen gelten (z.B. zum Schutz von Dritten oder von
          Geschäftsgeheimnissen). Wir werden Sie ggf. entsprechend informieren.Wenn Sie mit unserem Umgang mit Ihren Rechten oder dem
          Datenschutz nicht einverstanden sind, teilen Sie das uns (Ziff. 2) bitte mit. Insbesondere wenn Sie sich im EWR, im Vereinigten
          Königreich oder in der Schweiz befinden, haben Sie zudem das Recht, sich bei der Datenschutz-Aufsichtsbehörde Ihres Landes zu
          beschweren. Eine Liste der Behörden im EWR finden Sie hier:{' '}
          <Link href="https://edpb.europa.eu/about-edpb/board/members_de" target="_blank">
            https://edpb.europa.eu/about-edpb/board/members_de
          </Link>
          . Die Aufsichtsbehörde des Vereinigten Königreichs erreichen Sie hier:{' '}
          <Link href="https://ico.org.uk/global/contact-us/" target="_blank">
            https://ico.org.uk/global/contact-us/
          </Link>
          . Die Schweizer Aufsichtsbehörde erreichen Sie hier:{' '}
          <Link href="https://www.edoeb.admin.ch/edoeb/de/home/der-edoeb/kontakt/adresse.html" target="_blank">
            https://www.edoeb.admin.ch/edoeb/de/home/der-edoeb/kontakt/adresse.html
          </Link>
          .
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          12. Verwenden wir Online-Tracking- und Online-Werbetechniken?
        </Typography>

        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Auf unserer Website setzen wir verschiedene Techniken ein, mit denen wir und von uns beigezogenen Dritte Sie bei Ihrer Nutzung
          wiedererkennen und unter Umständen auch über mehrere Besuche hinweg verfolgen können. In diesem Abschnitt informieren wir Sie
          darüber.Im Kern geht es darum, dass wir die Zugriffe von Ihnen (über Ihr System) von Zugriffen anderer Benutzer unterscheiden
          können, damit wir die Funktionalität der Website sicherstellen und Auswertungen und Personalisierungen vornehmen können. Wir
          wollen dabei nicht auf Ihre Identität schliessen, auch wenn wir dies können, soweit wir oder von uns beigezogene Dritte Sie durch
          Kombination mit Registrierungsdaten identifizieren können. Auch ohne Registrierungsdaten sind die eingesetzten Techniken aber so
          ausgestaltet, dass Sie bei jedem Seitenaufruf als individueller Besucher erkannt werden, etwa indem unser Server (oder die Server
          der Dritten) Ihnen bzw. Ihrem Browser eine bestimmte Erkennungsnummer zuweist (sog. «Cookie»).Wir verwenden solche Techniken auf
          unserer Website und erlauben bestimmten Dritten, dies ebenfalls zu tun. Je nach dem Zweck dieser Techniken fragen wir aber nach
          Ihrer Einwilligung, bevor diese zum Einsatz kommen. Sie können Ihren Browser so programmieren, dass er bestimmte Cookies oder
          Alternativtechniken blockiert, täuscht oder bestehende Cookies löscht. Sie können Ihren Browser auch mit Software erweitern, die
          das Tracking durch bestimmte Dritte sperrt. Weitere Angaben dazu finden Sie auf den Hilfeseiten Ihres Browsers (meist unter dem
          Stichwort «Datenschutz») oder auf den Websites der Dritten, die wir unten aufführen.Es werden folgende Cookies (Techniken mit
          vergleichbaren Funktionsweisen wie das Fingerprinting sind hier mitgemeint) unterschieden:
          <br />
          <b>Notwendige Cookies:</b> Einige Cookies sind für das Funktionieren der Website als solche oder bestimmte Funktionen notwendig.
          Sie stellen z.B. sicher, dass Sie zwischen den Seiten wechseln können, ohne dass in einem Formular eingegebene Angaben verloren
          gehen. Sie stellen ausserdem sicher, dass Sie eingeloggt bleiben. Diese Cookies bestehen nur temporär («Session Cookies»). Falls
          Sie sie blockieren, funktioniert die Website möglicherweise nicht. Andere Cookies sind notwendig, damit der Server von Ihnen
          getroffene Entscheide oder Eingaben über eine Sitzung (d.h. einen Besuch der Website) hinaus speichern kann, falls Sie diese
          Funktion beanspruchen (z.B. gewählte Sprache, erteilte Einwilligung, die Funktion für ein automatisches Einloggen etc.). Diese
          Cookies haben ein Verfallsdatum von bis zu 24 Monaten.
          <br />
          <b>Performance Cookies:</b> Um unsere Website und entsprechende Angebote zu optimieren und besser auf die Bedürfnisse der Benutzer
          abzustimmen, nutzen wir Cookies, um die Verwendung unserer Website aufzuzeichnen und zu analysieren, unter Umständen auch über die
          Sitzung hinaus. Das tun wir durch den Einsatz von Analyse-Diensten von Drittanbietern. Diese haben wir unten aufgeführt. Bevor wir
          solche Cookies einsetzen, bitten wir Sie um Ihre Zustimmung. Performance Cookies haben ebenfalls ein Verfallsdatum von bis zu 24
          Monaten. Details finden Sie auf den Websites der Drittanbieter.
          <br />
          <b>Marketing Cookies:</b> Wir und unsere Werbe-Vertragspartner haben ein Interesse daran, Werbung zielgruppengenau zu steuern,
          d.h. möglichst nur denen anzuzeigen, die wir ansprechen wollen. Unsere Werbe-Vertragspartner haben wir unten aufgeführt. Zu diesem
          Zweck setzen wir und unsere Werbe-Vertragspartner – falls Sie einwilligen – ebenfalls Cookies ein, mit denen die aufgerufenen
          Inhalte oder geschlossenen Verträge erfasst werden können. Das ermöglicht es uns und unseren Werbe-Vertragspartnern, Werbung
          anzuzeigen, bei der wir davon ausgehen können, dass sie Sie interessiert, auf unserer Website, aber auch auf anderen Websites, die
          Werbung von uns bzw. unseren Werbe-Vertragspartnern anzeigen. Diese Cookies haben je nach Situation eine Verfalldauer von einigen
          Tagen bis zu 12 Monaten. Falls Sie in die Verwendung dieser Cookies einwilligen, wird Ihnen entsprechende Werbung angezeigt. Falls
          Sie nicht in diese Cookies einwilligen, sehen Sie nicht weniger Werbung, sondern einfach irgendwelche andere Werbung.Wir können
          auf unserer Website auch weitere Angebote Dritter einbinden, insbesondere von Social Media-Anbietern. Diese Angebote sind dabei
          standardmässig deaktiviert. Sobald Sie sie aktivieren (z.B. durch Anklicken eines Schalters), können die entsprechenden Anbieter
          feststellen, dass Sie sich auf unserer Website befinden. Haben Sie beim Social Media-Anbieter ein Konto, kann er Ihnen diese
          Angabe zuordnen und so Ihre Nutzung von Online-Angeboten verfolgen. Diese Social Media-Anbieter bearbeiten diese Daten in eigener
          Verantwortung.
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          13. Welche Daten bearbeiten wir auf unseren Seiten in sozialen Netzwerken?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Wir können auf sozialen Netzwerken und anderen von Dritten betriebenen Plattformen Seiten und sonstige Online-Präsenzen betreiben
          («Fanpages», «Kanäle», «Profile» etc.) und dort die in Ziff. 3 und nachfolgend umschriebenen Daten über Sie erheben. Wir erhalten
          diese Daten von Ihnen und den Plattformen, wenn Sie über unsere Online-Präsenz mit uns in Kontakt kommen (z.B., wenn Sie mit uns
          kommunizieren, unsere Inhalte kommentieren oder unsere Präsenz besuchen). Gleichzeitig werten die Plattformen Ihre Nutzung unserer
          Online-Präsenzen aus und verknüpfen diese Daten mit weiteren, den Plattformen bekannten Daten über Sie (z.B. zu Ihrem Verhalten
          und Ihren Präferenzen). Sie bearbeiten diese Daten auch für eigene Zwecke in eigener Verantwortlichkeit, insbesondere für
          Marketing- und Marktforschungszwecke (z.B. um Werbung zu personalisieren) und um ihre Plattformen zu steuern (z.B. welche Inhalte
          sie Ihnen anzeigen). Wir bearbeiten diese Daten zu den in Ziff. 4 beschriebenen Zwecken, so insbesondere zur Kommunikation, für
          Marketingzwecke (einschliesslich Werbung auf diesen Plattformen, vgl. dazu Ziff. 12) und zur Marktforschung. Zu den entsprechenden
          Rechtsgrundlagen finden Sie Angaben in Ziff. 5. Von Ihnen selbst veröffentlichte Inhalte (z.B. Kommentare zu einer Ankündigung)
          können wir selbst weiterverbreiten (z.B. in unserer Werbung auf der Plattform oder anderswo). Wir oder die Betreiber der
          Plattformen können Inhalte von oder zu Ihnen auch entsprechend den Nutzungsrichtlinien löschen oder einschränken (z.B.
          unangemessene Kommentare). Weitere Angaben zu den Bearbeitungen der Betreiber der Plattformen entnehmen Sie bitte den
          Datenschutzhinweisen der Plattformen. Dort erfahren Sie auch, in welchen Ländern diese ihre Daten bearbeiten, welche Auskunfts-,
          Lösch- und weiteren Betroffenenrechte Sie haben und wie Sie diese wahrnehmen oder weitere Informationen erhalten können. Derzeit
          nutzen wir folgende Plattformen: LinkedIn: Wir verfügen über ein Profil bei LinkedIn. Anbieter ist die LinkedIn Ireland Unlimited
          Company, Wilton Plaza, Wilton Place, Dublin 2, Irland. LinkedIn verwendet Werbecookies. Wenn Sie LinkedIn-Werbe-Cookies
          deaktivieren möchten, nutzen Sie bitte folgenden Link:{' '}
          <Link href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" target="_blank">
            https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
          </Link>
          . Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier:{' '}
          <Link href="https://www.linkedin.com/legal/l/dpa" target="_blank">
            https://www.linkedin.com/legal/l/dpa
          </Link>{' '}
          und{' '}
          <Link href="https://www.linkedin.com/legal/l/eu-sccs" target="_blank">
            https://www.linkedin.com/legal/l/eu-sccs
          </Link>
          . Details zu deren Umgang mit Ihren personenbezogenen Daten entnehmen Sie der Datenschutzerklärung von LinkedIn:{' '}
          <Link href="https://www.linkedin.com/legal/privacy-policy" target="_blank">
            https://www.linkedin.com/legal/privacy-policy
          </Link>
          . YouTube: Wir verfügen über ein Profil bei YouTube. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin
          4, Irland. Details zu deren Umgang mit Ihren personenbezogenen Daten entnehmen Sie der Datenschutzerklärung von YouTube:{' '}
          <Link href="https://policies.google.com/privacy?hl=de" target="_blank">
            https://policies.google.com/privacy?hl=de
          </Link>
          .
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          14. Können diese Datenschutzhinweise geändert werden?
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          Diese Datenschutzhinweise sind nicht Bestandteil eines Vertrags mit Ihnen. Wir können diese Datenschutzhinweise jederzeit
          anpassen. Die auf dieser Website veröffentlichte Version ist die jeweils aktuelle Fassung. Letzte Aktualisierung: 7.4.2024
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={close}>
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;
