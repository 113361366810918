import { Backdrop, Box, Button, Fade, Paper, Stack, Typography } from '@mui/material';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import Privacy from '../components/modals/Privacy';
import { SnackBarContext } from '../context/SnackBarContext';
import AGB from '../components/modals/AGB';
import { colors } from '../theme';

const CookieConsent = () => {
  const { addModal } = useContext(SnackBarContext);
  const [bannerOpen, setBannerOpen] = useState(true);

  const [cookies, setCookie] = useCookies(['consent']);

  const closeBanner = () => {
    setBannerOpen(false);
    setCookie('consent', true);
    window.consentGranted();
  };

  return (
    <>
      {!cookies.consent && (
        <Backdrop open={bannerOpen}>
          <TrapFocus open disableAutoFocus disableEnforceFocus>
            <Fade appear={false} in={bannerOpen}>
              <Paper
                role="dialog"
                aria-modal="false"
                aria-label="Cookie banner"
                square
                variant="outlined"
                tabIndex={-1}
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  m: 0,
                  p: 2,
                  borderWidth: 0,
                  borderTopWidth: 1,
                  pl: 5,
                  pr: 5
                }}
              >
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={2}>
                  <Box
                    sx={{
                      flexShrink: 1,
                      alignSelf: { xs: 'flex-start', sm: 'center' }
                    }}
                  >
                    <Typography variant="body2">
                      Wir verwenden Cookies und ähnliche Technologien, um dein Erlebnis auf unserer Website stetig zu verbessern. Mit der
                      Nutzung dieser Website akzeptierst du unsere{' '}
                      <span
                        style={{ color: colors.red, cursor: 'pointer' }}
                        onClick={() => {
                          addModal(<Privacy />);
                        }}
                      >
                        Datenschutzhinweise
                      </span>{' '}
                      und unsere{' '}
                      <span
                        style={{ color: colors.red, cursor: 'pointer' }}
                        onClick={() => {
                          addModal(<AGB />);
                        }}
                      >
                        AGBs
                      </span>
                      .
                    </Typography>
                  </Box>
                  <Stack
                    gap={2}
                    direction={{
                      xs: 'row-reverse',
                      sm: 'row'
                    }}
                    sx={{
                      flexShrink: 0,
                      alignSelf: { xs: 'flex-end', sm: 'center' }
                    }}
                  >
                    <Button size="small" onClick={closeBanner} variant="contained">
                      Akzeptieren
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </Fade>
          </TrapFocus>
        </Backdrop>
      )}
    </>
  );
};

export default CookieConsent;
