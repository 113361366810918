import { AppBar, Box, Button, ButtonGroup, Divider, Drawer, Toolbar, Typography } from '@mui/material';
import EqIcon from './icons/EqIcon';
import { Link } from 'gatsby';
import { routes } from '../routes';
import { useContext, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from '@reach/router';
import { AccountCircle } from '@mui/icons-material';
import { colors } from '../theme';
import { AuthContext } from '../context/AuthContext';

const TopBar = () => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 300 }} role="presentation" onClick={toggleDrawer(false)}>
      <CloseIcon
        sx={{
          float: 'right',
          cursor: 'pointer',
          mr: '10px',
          mt: '15px'
        }}
        onClick={() => {
          toggleDrawer(false);
        }}
      />
      <Button
        component={Link}
        to={routes.pricing.path}
        sx={{
          width: '100%',
          justifyContent: 'left',
          fontWeight: location.pathname === routes.pricing.path ? 'bold' : 'normal',
          paddingLeft: '23px'
        }}
      >
        Preise
      </Button>
      <Divider variant="middle" sx={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} />
      <Button
        component={Link}
        to={routes.functionality.path}
        sx={{
          width: '100%',
          justifyContent: 'left',
          fontWeight: location.pathname === routes.functionality.path ? 'bold' : 'normal',
          paddingLeft: '23px'
        }}
      >
        Funktionen
      </Button>
      <Divider variant="middle" sx={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} />
      <Button
        component={Link}
        to={routes.faq.path}
        sx={{
          width: '100%',
          justifyContent: 'left',
          fontWeight: location.pathname === routes.faq.path ? 'bold' : 'normal',
          paddingLeft: '23px'
        }}
      >
        FAQ
      </Button>
      <Divider variant="middle" sx={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} />
      <Button
        component={Link}
        to={routes.aboutUs.path}
        sx={{
          width: '100%',
          justifyContent: 'left',
          fontWeight: location.pathname === routes.aboutUs.path ? 'bold' : 'normal',
          paddingLeft: '23px'
        }}
      >
        Über swiss transcribe
      </Button>
      <Divider variant="middle" sx={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} />
      <Button
        component={Link}
        to={routes.prepaid.path}
        sx={{
          width: '100%',
          justifyContent: 'left',
          fontWeight: location.pathname === routes.prepaid.path ? 'bold' : 'normal',
          paddingLeft: '23px'
        }}
      >
        Guthaben aufladen
      </Button>
      <Divider variant="middle" sx={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} />
      <Button
        component={Link}
        to={routes.cockpit.path}
        sx={{
          width: '100%',
          justifyContent: 'left',
          backgroundColor: location.pathname === routes.cockpit.path ? '#A33A3B' : '',
          paddingLeft: '23px',
          '.MuiButton-startIcon': {
            marginRight: '4px',
            '.MuiSvgIcon-root': {
              color: authContext?.user ? colors.green : 'white'
            }
          }
        }}
        startIcon={<AccountCircle />}
      >
        Cockpit
      </Button>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
      <Toolbar
        sx={{
          ml: {
            xs: 2,
            md: 0,
            lg: 2
          },
          mr: {
            xs: 2,
            md: 0,
            lg: 2
          },
          mt: 2,
          mb: 2,
          p: 0
        }}
      >
        <Link to={routes.home.path} style={{ display: 'contents', color: 'unset' }}>
          <EqIcon />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography
              color="white"
              fontFamily="HandelGothicRegular"
              sx={{
                fontSize: {
                  sx: '18px',
                  sm: '28px'
                }
              }}
              paddingLeft="16px"
            >
              swiss transcribe
            </Typography>
          </Box>
        </Link>
        <ButtonGroup
          variant="contained"
          aria-label="Basic button group"
          sx={{
            display: {
              xs: 'none',
              lg: 'block'
            },
            ml: 'auto'
          }}
        >
          <Button
            component={Link}
            to={routes.pricing.path}
            sx={{
              backgroundColor: location.pathname === routes.pricing.path ? '#A33A3B' : ''
            }}
          >
            Preise
          </Button>
          <Button
            component={Link}
            to={routes.functionality.path}
            sx={{
              backgroundColor: location.pathname === routes.functionality.path ? '#A33A3B' : ''
            }}
          >
            Funktionen
          </Button>
          <Button
            component={Link}
            to={routes.faq.path}
            sx={{
              backgroundColor: location.pathname === routes.faq.path ? '#A33A3B' : ''
            }}
          >
            FAQ
          </Button>
          <Button
            component={Link}
            to={routes.aboutUs.path}
            sx={{
              backgroundColor: location.pathname === routes.aboutUs.path ? '#A33A3B' : ''
            }}
          >
            Über swiss transcribe
          </Button>
          <Button
            component={Link}
            to={routes.prepaid.path}
            sx={{
              backgroundColor: location.pathname === routes.prepaid.path ? '#A33A3B' : ''
            }}
          >
            Guthaben aufladen
          </Button>
          <Button
            component={Link}
            to={routes.cockpit.path}
            sx={{
              backgroundColor: location.pathname === routes.cockpit.path ? '#A33A3B' : '',
              '.MuiButton-startIcon': {
                marginRight: '4px',
                '.MuiSvgIcon-root': {
                  color: authContext?.user ? colors.green : 'white'
                }
              }
            }}
            startIcon={<AccountCircle />}
          >
            Cockpit
          </Button>
        </ButtonGroup>
        <Button
          sx={{
            ml: 'auto',
            display: {
              xs: 'flex',
              lg: 'none'
            },
            alignItems: 'center',
            justifyContent: 'end',
            color: 'white',
            padding: '0'
          }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </Button>
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
        {/* <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}></Typography> */}
        {/* <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton> */}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
