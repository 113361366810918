import { Environment } from '../types';
import { Config } from './types';
import { configDev } from './dev';

// ----------- THIS DATA IS HYDRATED ON THE FRONTEND!!!! ------------
export const configLocal: Config = {
  ...configDev,
  environment: Environment.LOCAL,
  frontendBaseUrl: 'http://localhost:3000',
  frontendCockpitBaseUrl: 'http://localhost:5173',
  apiBaseUrl: 'http://localhost:3001'
};
