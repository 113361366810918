import { createTheme } from '@mui/material';
import './fonts.css';

export const colors = {
  red: '#ea5455',
  black: 'black',
  border: '#E0E3E7',
  pink: '#eda9a2',
  green: '#c4d9a6',
  gold: '#eecc88',
  grey: '#f5f5f5'
};

// here we can see the default theme:
// https://mui.com/material-ui/customization/default-theme/
const defaultTheme = createTheme({
  palette: {
    background: {
      default: colors.black
    },
    primary: {
      main: '#ea5455',
      light: '#e42728'
    }
    // secondary: {
    //   main: 'green',
    //   dark: 'green'
    // }
  },
  typography: {
    htmlFontSize: 16,
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 16,
    fontFamily: 'HelveticaNeue',
    body1: {
      fontWeight: 500
    },
    button: {
      fontWeight: 500,
      textTransform: 'none'
    },
    subtitle1: {
      fontWeight: 300,
      fontSize: 14,
      lineHeight: 14,
      color: 'GrayText'
    },
    overline: {
      fontWeight: 130
    },
    h1: {
      fontWeight: 600,
      lineHeight: 1
    },
    h2: {
      fontWeight: 600,
      lineHeight: 1
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': colors.border,
          '--TextField-brandBorderHoverColor': colors.border,
          '--TextField-brandBorderFocusedColor': colors.border,
          // '& label.Mui-focused': {
          //   color: 'var(--TextField-brandBorderFocusedColor)'
          // }
          '& label': {
            top: '8px'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&': {
            color: colors.black
          },
          '&.Mui-focused': {
            color: colors.black
          }
        }
      }
    },
    MuiSelect: {},
    MuiInput: {
      styleOverrides: {
        root: {
          '&::before': {
            borderBottom: '1px solid var(--TextField-brandBorderColor)'
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '1px solid var(--TextField-brandBorderHoverColor)'
          },
          '&.Mui-focused:after': {
            borderBottom: '1px solid var(--TextField-brandBorderFocusedColor)'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.red
        }
      }
    }
  }
});

const redTheme = createTheme(defaultTheme, {
  palette: {
    background: {
      default: colors.red
    }
  }
});

export { defaultTheme, redTheme };
