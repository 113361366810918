import { Breakpoint, Container, CssBaseline, Theme, ThemeProvider } from '@mui/material';
import React from 'react';
import TopBar from '../components/AppBar';
import { defaultTheme } from '../theme';
import Footer from './Footer';
import CookieConsent from './CookieConsent';

type Props = {
  children: React.ReactNode;
  theme?: Theme;
  maxWidth?: false | Breakpoint | undefined;
};

const Layout = ({ maxWidth, theme, children }: Props) => {
  return (
    <ThemeProvider theme={theme ? theme : defaultTheme}>
      <TopBar />
      <CssBaseline />
      <Container
        maxWidth={maxWidth ? maxWidth : 'lg'}
        sx={{
          pl: {
            xs: 2,
            md: 8
          },
          pr: {
            xs: 2,
            md: 8
          },
          pt: {
            xs: 2,
            sm: 8,
            md: 14
          },
          mb: 8,
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        {children}
      </Container>
      <Footer />
      {typeof window !== 'undefined' && <CookieConsent />}
    </ThemeProvider>
  );
};

export default Layout;
