import config from './configs';

const routes = {
  home: {
    path: '/'
  },
  pricing: {
    path: '/preise/'
  },
  functionality: {
    path: '/funktionen/'
  },
  faq: {
    path: '/faq/'
  },
  aboutUs: {
    path: '/ueber-uns/'
  },
  prepaid: {
    path: '/prepaid/'
  },
  cockpit: {
    path: `/cockpit/`
  },
  cockpitTranscriptions: {
    path: `${config.frontendCockpitBaseUrl}/cockpit/transcriptions`
  },
  imprint: {
    path: '/imprint/'
  }
};

export { routes };
