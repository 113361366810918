import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

type Props = {
  close?: () => void;
};

const Component = ({ close }: Props) => {
  return (
    <Dialog open onClose={close} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title">Allgemeine Geschäftsbedingungen von swiss transcribe</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 1 - Zustandekommen des Vertrags
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          1.1 Zweck der vorliegenden Bedingungen ist es, die Bedingungen festzulegen, zu denen Swiss Transcribe dem Kunden die auf der
          Website swisstranscribe.ch genannten Dienstleistungen zur Verfügung stellt. Die Allgemeinen Nutzungsbedingungen (nachfolgend "AGB"
          genannt) werden zwischen Candoo GmbH, einem Schweizer Unternehmen mit Sitz in St. Moritz (nachfolgend „Swiss Transcribe“ genannt),
          und jeder natürlichen oder juristischen Person des privaten oder öffentlichen Rechts (nachfolgend "Kunde" genannt) geschlossen,
          die den Swiss Transcribe Service nutzt. <br />
          <br />
          1.2 Die Nutzung des Swiss-Transcribe-Dienstes schliesst die Annahme des auf der Swiss-Transcribe-Website verfügbaren Anhangs über
          die Verarbeitung von Daten ein. Dieses Dokument bildet zusammen mit den AGB einen Vertrag (nachfolgend der "Vertrag").
          <br />
          <br /> 1.3 Swiss Transcribe behält sich das Recht vor, die vorliegenden AGB und die Anhänge zum vorliegenden Vertrag jederzeit zu
          ändern.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 2 - Beschreibung und Umfang der Dienstleitung
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          2.1 Swiss Transcribe stellt dem Kunden eine digitale Infrastruktur für den Austausch von Dateien zur Verfügung.
          <br />
          <br /> 2.2 Nach Ablauf des Verfügbarkeitszeitraums werden die Dateien dauerhaft von den Swiss-Transcribe-Servern gelöscht.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 3 - Verpflichtungen und Verantworlichen von Swiss Transcribe
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          3.1 Swiss Transcribe garantiert dem Kunden, dass alle von Swiss Transcribe und über Swiss Transcribe übermittelten Daten mit der
          Sorgfalt und Gewissenhaftigkeit behandelt werden, die für die Erbringung und Aufrechterhaltung einer Qualitätsdienstleistung
          gemäss den Gepflogenheiten des Berufsstandes erforderlich sind.
          <br />
          <br />
          3.2 Swiss Transcribe verpflichtet sich, die heruntergeladenen und zwischen dem Kunden und den Personen, die den Download-Link
          erhalten, ausgetauschten Dateien nicht weiterzuverarbeiten oder damit zu handeln.
          <br />
          <br />
          3.3 Swiss Transcribe verpflichtet sich, die Swiss Transcribe anvertrauten E-Mail-Adressen nicht zu kommerziellen Zwecken zu nutzen
          und sie ausschliesslich für die Weitergabe des Download-Links gemäss den Wünschen des Kunden zu verwenden.
          <br />
          <br />
          3.4 Swiss Transcribe kann den Zugang zum Dienst einschränken, wenn die Stabilität und Sicherheit des Betriebs, die
          Aufrechterhaltung der Integrität des Netzes, insbesondere die Vermeidung schwerwiegender Störungen des Netzes, der Software oder
          der gespeicherten Daten dies erfordern.
          <br />
          <br />
          3.5 Swiss Transcribe ist nicht verpflichtet, die Nutzung des Dienstes durch den Kunden zu kontrollieren oder zu überwachen, um
          deren Rechtmässigkeit sicherzustellen. Alle hochgeladenen Dateien werden jedoch gescannt, um die Verbreitung von bösartigen
          Dateien zu verhindern. Zu diesem Zweck und für den Fall, dass eine bösartige Datei entdeckt wird, wird ihre Übermittlung von Swiss
          Transcribe nicht zugelassen.
          <br />
          <br />
          3.7 Swiss Transcribe ist berechtigt, den Inhalt (einschliesslich personenbezogener Daten) im Rahmen des vorliegenden Vertrags zu
          verwenden oder weiterzugeben, wenn die Situation dies zur Wahrung seiner Interessen oder der Interessen Dritter erfordert.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 4 - Pflichten und Verantwortlichkeiten des Kunden
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          4.1 Der Kunde muss die Befugnis, Autorität und Kapazität haben, die Verpflichtungen aus diesem Vertrag einzugehen und zu erfüllen.
          <br />
          <br />
          4.2 Wenn der Kunde die Dienste im Namen eines Dritten nutzt, erklärt und garantiert er Swiss Transcribe, dass er über die
          erforderliche Befugnis und Kapazität verfügt, um den Dritten unter den im Vertrag festgelegten Bedingungen zu vertreten und zu
          verpflichten.
          <br />
          <br />
          4.3 Der Kunde trägt die volle Verantwortung für die Dateien, die über das Produkt Swiss Transcribe übertragen werden. In diesem
          Zusammenhang erklärt und garantiert der Kunde, dass er befugt ist, alle Materialien, Inhalte, Daten und Informationen, die Swiss
          Transcribe im Rahmen der Nutzung des Dienstes übermittelt werden, zu verwenden. Der Kunde behält das Eigentum an den Inhalten, die
          er in den Dienst hochlädt.
          <br />
          <br />
          4.4 Der Kunde darf nur Dateien hochladen, die ihm gehören oder, falls es sich um Dateien handelt, die ihm nicht gehören oder an
          denen er keine Eigentumsrechte hat, wenn er ausdrücklich dazu befugt ist.
          <br />
          <br />
          4.5 Der Kunde ist für alle Ansprüche verantwortlich, die sich aus einer Verletzung der Vertragsbedingungen durch den Dritten
          ergeben, und muss Swiss Transcribe in vollem Umfang für alle Ansprüche entschädigen, die sich aus der Nutzung des Dienstes durch
          den Dritten ergeben.
          <br />
          <br />
          4.6 Der Kunde wird nicht versuchen, sich unbefugten Zugang zu einem Dienst oder Inhalt oder zu damit verbundenen Systemen oder
          Netzwerken zu verschaffen, und erklärt sich auch damit einverstanden, keine Dokumente oder Informationen auf eine Weise zu
          erhalten oder zu versuchen, die nicht absichtlich von Swiss Transcribe zur Verfügung gestellt wurde.
          <br />
          <br />
          4.7 Jede Nutzung des Dienstes durch den Kunden, die gegen diese Vereinbarung verstößt und die die Sicherheit, Integrität oder
          Verfügbarkeit unserer Dienste gefährden könnte, kann zur sofortigen Aussetzung des Dienstes führen.
          <br />
          <br />
          4.8 Dies umfasst unter anderem die Begehung einer Straftat (Betrug, Computerkriminalität, Geldwäsche, Verletzung von
          Geschäftsgeheimnissen, Fälschung von Dokumenten, Gewalt und Drohungen gegen Behörden und Beamte, unerlaubtes Glücksspiel, etc. ),
          die Teilnahme an einer Straftat (Kollaboration, Anstiftung, Beihilfe), die Verbreitung oder Bereitstellung von Inhalten, die gegen
          straf- oder zivilrechtliche Bestimmungen verstoßen (Gewaltdarstellungen, Anstiftung zur Störung des öffentlichen Friedens,
          Verletzung der Religions- und Kulturfreiheit, Rassendiskriminierung, Diffamierung, Verleumdung, Verletzung der Privatsphäre usw.),
          führen zur sofortigen Beendigung des Vertrags, d. h. zur Sperrung und/oder Zerstörung des Freigabelinks, unbeschadet etwaiger
          Schadenersatzansprüche des Kunden. Darüber hinaus kann Swiss Transcribe alle zuständigen Behörden über Verstöße informieren, wenn
          sie dies für notwendig erachtet.
          <br />
          <br />
          4.9 Als Bedingung für den Zugang und die Nutzung der Dienste von Swiss Transcribe durch den Kunden verpflichtet sich dieser, Swiss
          Transcribe sowie alle an der Bereitstellung des Dienstes beteiligten Personen in Bezug auf alle Ansprüche, Forderungen,
          Verantwortlichkeiten, Kosten und/oder Ausgaben, die sich aus einer illegalen Nutzung des Dienstes oder seiner Inhalte ergeben, zu
          verteidigen, zu entschädigen und schadlos zu halten. Im Falle einer Inanspruchnahme hat der Kunde das Recht, Swiss Transcribe
          nachzuweisen, dass die Ansprüche aus der Freistellung nicht in dem erforderlichen Umfang entstanden sind und/oder dass der Kunde
          nicht verantwortlich ist.
          <br />
          <br />
          4.10 Es liegt in der alleinigen Verantwortung des Kunden zu wissen, an wen er seine Download-Links weitergibt. Die Person, die
          einen Download-Link vom Kunden erhält, kann frei auf den Download der Dateien zugreifen. Es obliegt daher dem Kunden, auf diesen
          Punkt zu achten, insbesondere im Hinblick auf die Folgen, die das Filesharing für den Kunden haben könnte.
          <br />
          <br />
          4.11 Der Kunde wird die Eigentumsrechte von Swiss Transcribe an der Website und der für die Erbringung des Dienstes verwendeten
          Software respektieren (zu den Eigentumsrechten gehören unter anderem Patente, Marken, Dienstleistungsmarken, Geschäftsgeheimnisse,
          Urheberrechte und andere geistige Eigentumsrechte).
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 5 - Datenschutz
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          5.1 Für weitere Einzelheiten wird der Kunde gebeten, die Datenschutzrichtlinie auf der Swiss-Transcribe-Website zu lesen.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 6 - Kommunikation zwischen den Vertragsparteien
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          6.1 Mit der Annahme des vorliegenden Vertrags erkennt der Kunde an, dass der im Vertrag vorgesehene Informationsaustausch, die
          Benachrichtigung oder förmliche Mitteilung zwischen den Parteien per E-Mail erfolgen muss.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 7 - Beschwerde einer dritten Person bei Swiss Transcribe
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          7.1 Wenn ein Dritter Swiss Transcribe eine Beschwerde über die Nutzung des Dienstes durch den Kunden übermittelt, wird Swiss
          Transcribe dem Kunden so schnell wie möglich und soweit möglich eine Warn-E-Mail schicken und eine Kopie der Beschwerde des
          Dritten übermitteln.
          <br />
          <br />
          7.2 Darüber hinaus hat Swiss Transcribe das Recht, die ihr zur Verfügung stehenden Daten an den Dritten, der sich als geschädigt
          ansieht, weiterzugeben, sofern die angeforderten Informationen dazu dienen, die Rechtsverfolgung zur Durchsetzung seiner Rechte zu
          erleichtern.
          <br />
          <br />
          7.3 Swiss Transcribe behält sich ausserdem das Recht vor, dem Kunden alle Kosten für Interventionen in Rechnung zu stellen, die
          sich aus den Handlungen des Kunden ergeben und die zu einer Beschwerde oder zur Einleitung eines Verfahrens gegen Swiss Transcribe
          geführt hätten, ohne dass dies jedoch vollständig wäre.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 8 - Vereinbarung von Beweisen
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          8.1 Für die Zwecke des vorliegenden Vertrags vereinbaren die Parteien, dass Schrift in elektronischer Form genauso als
          Beweismittel anerkannt wird wie Schrift auf Papier. Es wird vereinbart, dass die Daten aus dem Informationssystem von Swiss
          Transcribe oder seinen Unterauftragnehmern, wie z.B. Verbindungsprotokolle, Berichte über das Störungsmanagement oder andere, dem
          Kunden in vollem Umfang entgegengehalten werden können und zulässig sind, auch im Rahmen von Gerichtsverfahren. Der Austausch von
          E-Mails gilt als von den SMTP-Servern von Swiss Transcribe empfangen oder gesendet. Das Datum und die Uhrzeit der Mailserver von
          Swiss Transcribe gelten zwischen den Parteien als Beweis.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 9 - Streitigkeiten und Rechtsstreitigkeiten
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          9.1 Swiss Transcribe und der Kunde bemühen sich um eine gütliche Beilegung von Streitigkeiten, die die Auslegung oder die
          Ausführung des vorliegenden Vertrags betreffen. Jede Beschwerde muss in Übereinstimmung mit dem Artikel "Kommunikation zwischen
          den Parteien" eingereicht werden. Nach Ausschöpfung aller gütlichen Rechtsbehelfe hat der Kunde das Recht, seine Ansprüche
          gegenüber Swiss Transcribe gerichtlich geltend zu machen.
          <br />
          <br />
          9.2 In Übereinstimmung mit den Bestimmungen dieses Vertrags wird für den Fall, dass es zwischen dem Kunden und einem Dritten zu
          einer Streitigkeit oder einem Vorprozess über eine Dienstleistung kommt, zwischen Swiss Transcribe und dem Kunden ausdrücklich
          vereinbart, dass letzterer allein für die Beilegung dieser Streitigkeit verantwortlich bleibt.
          <br />
          <br />
          9.3 Keine Bestimmung des Vertrages darf so ausgelegt werden, dass sie die Verantwortung von Swiss Transcribe im Falle von Betrug
          oder schwerem Verschulden einschränkt.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 10 - Höhere Gewalt
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          10.1 Höhere Gewalt ist definiert als ein unvorhersehbares und/oder unvermeidbares Ereignis, das sich der Kontrolle von Swiss
          Transcribe entzieht.
          <br />
          <br />
          10. 2 Swiss Transcribe kann nicht haftbar gemacht werden, wenn die Ausführung des Vertrags oder die Erfüllung von Verpflichtungen,
          die Swiss Transcribe im Rahmen dieses Vertrags obliegen, verhindert, eingeschränkt oder gestört wird, unter anderem durch Brand,
          Explosion, Ausfall der Übertragungsnetze, Zusammenbruch der öffentlichen Dienste oder der Telekommunikation, Zusammenbruch der
          Anlagen, Epidemie, Pandemie, Naturkatastrophen, Erdbeben, Überschwemmung, Stromausfall, Krieg, Embargo, Gesetz, Verfügung,
          Aufforderung oder Forderung einer Regierung, Streik, Boykott, Entzug der Genehmigung des Telekommunikationsbetreibers oder andere
          Umstände, die außerhalb der zumutbaren Kontrolle von Swiss Transcribe liegen.
          <br />
          <br />
          10. 3 Swiss Transcribe ist, vorbehaltlich einer unverzüglichen Benachrichtigung des Kunden, von der Erfüllung ihrer
          Verpflichtungen in dem Umfang entbunden, in dem eine solche Behinderung, Einschränkung oder Störung vorliegt, und der Kunde ist
          ebenfalls von der Erfüllung seiner Verpflichtungen in dem Umfang entbunden, in dem sich die Verpflichtungen dieser Partei auf die
          so behinderte, eingeschränkte oder gestörte Leistung beziehen, vorausgesetzt, dass die so betroffene Partei sich nach besten
          Kräften bemüht, solche Ursachen für die Nichterfüllung zu vermeiden oder zu beseitigen, und dass beide Parteien unverzüglich
          fortfahren, sobald diese Ursachen weggefallen sind oder beseitigt wurden.
          <br />
          <br />
          10.4 Die von einem Ereignis höherer Gewalt betroffene Vertragspartei unterrichtet die andere Vertragspartei regelmässig über die
          Aussichten auf die Beseitigung oder das Wiederauftreten des Ereignisses höherer Gewalt.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 11 - Unabhängigkeit der Bestimmungen
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          11.1 Sollte eine der Bestimmungen des Vertrags für rechtswidrig, nichtig oder unanwendbar erklärt werden, so berührt diese
          Nichtigkeit nicht die anderen Klauseln, die ihre volle Wirkung und Tragweite behalten. In diesem Fall verpflichtet sich Swiss
          Transcribe, die ungültige oder nicht durchsetzbare Klausel durch eine gültige Klausel zu ersetzen, die dem Sinn und Zweck der
          Klausel am ehesten entspricht, so dass der gemeinsame Wille der Parteien erhalten bleibt.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1 }}>
          Artikel 12 - Gerichtsstand und anwendbares Recht
        </Typography>
        <Typography variant="body1" color={'black'} sx={{ mb: 5 }}>
          12.1 Der Kunde verpflichtet sich, das geltende schweizerische und internationale Recht zu respektieren.
          <br />
          <br />
          12.2 Swiss Transcribe wird sich bemühen, die Urteile der Behörden der Mitgliedsländer der OECD zu respektieren.
          <br />
          <br />
          12.3 Alle Streitigkeiten im Zusammenhang mit dem vorliegenden Vertrag, seinem Abschluss, seiner Ausführung und seiner Auslegung
          unterliegen in Ermangelung einer gütlichen Einigung dem schweizerischen Recht, wobei der ausschliessliche Gerichtsstand St. Moritz
          ist.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={close}>
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;
