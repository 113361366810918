import { Box, Fab, Typography } from '@mui/material';
import { useContext } from 'react';
import { SnackBarContext } from '../context/SnackBarContext';
import AGB from '../components/modals/AGB';
import Privacy from '../components/modals/Privacy';
import { Link, navigate } from 'gatsby';
import { routes } from '../routes';
import EqIcon from './icons/EqIcon';
import { useLocation } from '@reach/router';

const Footer = () => {
  const location = useLocation();
  const { addModal } = useContext(SnackBarContext);

  return (
    <Box textAlign={'center'} sx={{ pt: 1, pl: 5, pr: 5, pb: 4 }}>
      <Typography variant="body1" color="white" fontSize={'14px'} sx={{ mb: 2 }}>
        © swiss transcribe 2024 |{' '}
        <span
          onClick={() => {
            addModal(<AGB />);
          }}
          style={{ cursor: 'pointer' }}
        >
          AGB
        </span>{' '}
        |{' '}
        <span
          onClick={() => {
            addModal(<Privacy />);
          }}
          style={{ cursor: 'pointer' }}
        >
          Datenschutz
        </span>{' '}
        |{' '}
        <Link
          to={routes.imprint.path}
          style={{ cursor: 'pointer', color: 'white', textDecoration: 'none' }}
        >
          Imprint
        </Link>
      </Typography>
      {location.pathname !== routes.home.path && (
        <Link to={routes.home.path}>
          <Fab color="primary" aria-label="add" variant="extended" size="small">
            <Box sx={{ mr: 1 }}>
              <EqIcon height="14px" /> Transkribieren
            </Box>
          </Fab>
        </Link>
      )}
    </Box>
  );
};

export default Footer;
