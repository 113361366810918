import { ThemeProvider } from '@mui/material';
import { ReactNode, useState, createContext, useMemo, FC, cloneElement } from 'react';
import { defaultTheme } from '../theme';

export interface SnackBarContextProps {
  addModal: (modalFC: JSX.Element) => void;
}

const snackBarDefaultValues: SnackBarContextProps = {
  addModal: () => {
    // empty
  }
};

type SnackBarProviderProps = {
  children: ReactNode;
};

export const SnackBarContext = createContext<SnackBarContextProps>(snackBarDefaultValues);

SnackBarContext.displayName = 'SnackBar';

export const SnackBarProvider: FC<SnackBarProviderProps> = ({ children }) => {
  const [modal, setModal] = useState<JSX.Element | null>(null);

  const addModal = (modalFC: JSX.Element) => {
    const updsted = cloneElement(modalFC, {
      close: () => {
        setModal(null);
      }
    });
    setModal(<ThemeProvider theme={defaultTheme}>{updsted}</ThemeProvider>);
  };

  const value = useMemo(
    () => ({
      addModal
    }),
    []
  );

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {modal}
    </SnackBarContext.Provider>
  );
};
