import { createContext, ReactNode, useEffect, useReducer } from 'react';

import { getMetadata, getSignOut } from '../api/auth';
import { ActionMap, AuthContextType, AuthState, User } from 'common';

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: User | null;
  };
  [SIGN_IN]: {
    user: User;
  };
  [SIGN_OUT]: undefined;
};

type AuthActions = ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

const reducer = (state: AuthState, action: AuthActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }
  if (action.type === SIGN_IN) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null
    };
  }
  return state;
};

const AuthContext = createContext<AuthContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await getMetadata();

        if (user) {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: true, user }
          });

          return;
        }
      } catch (err) {
        console.error(err);
      }
      dispatch({
        type: INITIALIZE,
        payload: { isAuthenticated: false, user: null }
      });
    };

    initialize();
  }, []);

  const signOut = async () => {
    await getSignOut();
    dispatch({ type: SIGN_OUT });
  };

  const signIn = async (user: User) => {
    dispatch({
      type: INITIALIZE,
      payload: { isAuthenticated: true, user }
    });
  };

  const reload = async () => {
    try {
      const user = await getMetadata();

      if (user) {
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: true, user }
        });

        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cookie',
        user: state.user,
        signIn,
        signOut,
        reload
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
