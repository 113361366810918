export enum CheckoutPackageTypes {
  Package_CHF_10 = '10',
  Package_CHF_15 = '15',
  Package_CHF_30 = '30',
  Package_CHF_60 = '60',
  Package_CHF_120 = '120'
}
export interface CheckoutPayload {
  email?: string;
  package: CheckoutPackageTypes;
}
